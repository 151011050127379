const {isMob, isTablet} = require('./media.js');



var scrollDog = $(".scroll-bar-dog"), scrollDog_height = $(scrollDog).height() , isDaragging = false;

scrollDog.get(0).addEventListener("touchstart", handleStart, {passive: true});
scrollDog.get(0).addEventListener("touchend", handleEnd, {passive: true});
scrollDog.get(0).addEventListener("touchcancel", handleCancel, {passive: true});
scrollDog.get(0).addEventListener("touchmove", handleMove, {passive: true});

// scrollDog.on("mousedown", handleStart);
// $(document).on("mouseup", handleEnd);
// $(document).on("mousemove", handleMove);

// Tracking new touches
function handleStart(evt) {
    evt.preventDefault();
    isDaragging = true;
}

function handleMove(evt){
   // console.log(evt)
    if(isDaragging){
        //var currentTouchPos = evt.clientY;
        var currentTouchPos = evt.changedTouches[0].clientY;
        var scrollPercent = currentTouchPos /  document.documentElement.clientHeight;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

        $('html, body').animate({
            'scrollTop': scrollPercent * height
        }, 0)
        //console.log(currentTouchPos);
    }
}

function handleEnd(evt){
    isDaragging = false;
}

function handleCancel(evt){
    isDaragging = false;
}

var timerDogScroll ;

document.addEventListener('scroll', function (e) {
    if( isTablet() || isMob() ){
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        var scrollDogPosition = (document.documentElement.clientHeight - scrollDog_height ) * scrolled / 100;

        //console.log(~~scrolled + "%");


        jQuery(".scroll-bar-dog").css({'top' : ~~ scrollDogPosition + 'px' }).fadeIn(400);

        document.addEventListener('scroll', () => {
            let scroll = $(window).scrollTop();

            if (scroll > 160) {
                jQuery(".scroll-bar-dog").removeClass('d-none');
            } else {
                jQuery(".scroll-bar-dog").addClass('d-none');
            }
        }, {passive: true});

        clearTimeout(timerDogScroll);

        timerDogScroll = setTimeout(function () {
            jQuery(".scroll-bar-dog").fadeOut(400);
        }, 2000);
    }
}, {passive: true});

// $(window).scroll(function(e) {
//
//     if( isTablet() || isMob() ){
//         var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
//         var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
//         var scrolled = (winScroll / height) * 100;
//         var scrollDogPosition = (document.documentElement.clientHeight - scrollDog_height ) * scrolled / 100;
//
//         //console.log(~~scrolled + "%");
//
//
//         jQuery(".scroll-bar-dog").css({'top' : ~~ scrollDogPosition + 'px' }).fadeIn(400);
//
//         clearTimeout(timerDogScroll);
//
//         timerDogScroll = setTimeout(function () {
//             jQuery(".scroll-bar-dog").fadeOut(400);
//         }, 2000);
//     }
// });

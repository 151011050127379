const media = require('./media');

export class TemplatesSlider {

    constructor(options) {
        this.options = $.extend({
            node: $('.templates__slider'),
            slickOptions: {
                dots: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3000,
                //centerMode: !$('.templates__slider').data('expand-mode'),
                centerMode: true,
                slidesToShow: 1,
                variableWidth: true,
                arrows: false
            }
        }, options);

        this.slickAdded = false;

        $(window).resize(() => {
            this.sliderResize();
        });

        this.sliderResize();
    }

    sliderResize() {
        if (media.isTablet()) {
            if (this.options.node.hasClass('slick-slider')) {
                this.options.node.slick('unslick');
            }
            this.slickAdded = false;
        } else {
            if (this.slickAdded) {
                this.options.node.slick('resize');
            } else {
                this.options.node.slick(this.options.slickOptions);
            }
           this. slickAdded = true;
        }
    }
}



export class Login {
    options = {
        companyLoginField: '#company-login',
        companyLoginError: '#company-login-error',
        companyLoginButton: '#company-login-button',
    };

    constructor(options) {
        let _this = this;
        let loginField = $(this.options.companyLoginField);

        _this.options = $.extend(_this.options, options);

        loginField.bind('keypress', function (event) {
            return /[a-z0-9]+/i.test(event.key);
        });

        loginField.bind('keyup', function () {
            $(this).val($(this).val().replace(/([^a-z0-9]+)/ig, ''));
        });

        $(this.options.companyLoginButton).click(function () {
            $(_this.options.companyLoginError).html('').hide();

            $.get(_this.options.path.loginCompanyLink + '/' + $(loginField).val(), function (result) {
                if (result.success) {
                    location.href = result.result;
                } else {
                    $(_this.options.companyLoginError).html(_t(result.error)).show();
                }
            }, 'json')
        });
    };
}
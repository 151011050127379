require('intersection-observer');

// Use IntersectionObserver with better performance
export function lazyImages() {
    var observer = new IntersectionObserver(function (data) {
        $(data).each(function () {
            if (this.isIntersecting) {
                if ($(this.target).data('lazy-src')) {
                    $(this.target).attr('src', $(this.target).data('lazy-src'));
                }
                if ($(this.target).data('lazy-background')) {
                    $(this.target).css('background-image', 'url(' + $(this.target).data('lazy-background') + ')');
                }

                observer.unobserve(this.target);
            }
        });
    });
    observer.POLL_INTERVAL = 100;
    observer.USE_MUTATION_OBSERVER = true;

    $('img[data-lazy-src], .sb-with-background').each(function () {
        observer.observe(this);
    });
}
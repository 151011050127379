import $ from "jquery";

export class Feedback {
    options = {
        feedbackListElement: '#feedback_list',
        showMoreElement: '#show_more_feedback',
        offset: 10,
        count: 0,
        path: {
            loadMore: null
        }
    };

    constructor(options) {
        this.options = $.extend(this.options, options);

        this.handleShowMoreClick();
    };

    handleShowMoreClick() {
        let _this = this;
        $(_this.options.showMoreElement).click(function () {
            let showMoreUrl = _this.options.path.loadMore + '/' + _this.options.offset;

            $.get(showMoreUrl, function (result) {
                $(_this.options.feedbackListElement).append(result);

                window.dispatchEvent(new Event('resize'));
                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, 1000)
            }, 'html');

            _this.options.offset += 10;

            if (_this.options.offset > _this.options.count) {
                $(_this.options.showMoreElement).hide();
            }
        });
    }
}
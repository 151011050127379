/**
 * @module effects
 * effects and animations
 */

/**
 * add "ripple" effect to button
 * @param $element
 */
export function addRippleToButton ($element) {
    $element.on("click", function (e) {
        let $target = $(e.currentTarget);

        var $ripple = $('<span class="ripple"/>').appendTo($target),
            size = $target.innerWidth() > $target.innerHeight() ? $target.innerWidth()*2 : $target.innerHeight()*2,
            clickY = $target.offset().top,
            clickX = $target.offset().left,
            x = e.pageX - clickX,
            y = e.pageY - clickY;

        $ripple.css({
            'top': y +'px',
            'left': x +'px'
        });

        $ripple.animate({
            'width': size + 'px',
            'height': size + 'px',
            'margin-top': -size/2 + 'px',
            'margin-left': -size/2 + 'px',
            'opacity': 0
        }, 750, function() {
            $(this).remove();
        });
    });
}


export class TooltipPositioning {

    constructor(options) {
        this.options = $.extend({
            node: $('.has-tooltip'),
        }, options);

        this._initPositioning();
    }

    _initPositioning() {
        this.options.node.on('mouseover', (event) => {
            let $target = $(event.currentTarget);
            let $tooltip = $target.children('.tooltip');

            let $tooltipBoundingRect = $tooltip[0].getBoundingClientRect();

            if (($tooltipBoundingRect.x + $tooltipBoundingRect.width) > $(window).width()) {
                $tooltip.addClass('tooltip--left').removeClass('tooltip--right');
            } else if ($tooltipBoundingRect.y < 0 ) {
                $tooltip.addClass('tooltip--right').removeClass('tooltip--left');
            }

        });
    }
}
const media = require('./media');

export class FooterAccordion {

    constructor (options) {
        this.options = $.extend({
            node: $('#footer')
        }, options);

        this._isMobMode = false;

        $(window).on('resize', () => {
            this._initAccordion();
        });

        this._initAccordion();
    }

    _initAccordion() {
        let isMobMode = media.isTablet();
        if (isMobMode == this._isMobMode) {
            return;
        }

        this._isMobMode = isMobMode;

        if (isMobMode) {
            // add accordion

            $('.categories ul', this.options.node).css('max-height', '0');
            $('.categories p .fa', this.options.node).removeClass('active');

            $('.categories p .fa', this.options.node).on('click', (event) => {
                let $target = $(event.currentTarget);
                console.log($target)
                let $navigation = $target.closest('.column').children('ul');

                $target.toggleClass('active');

                if ($navigation.height() != 0) {
                    $navigation.css('max-height', 0);
                    $navigation.removeClass('expanded');
                } else {
                    $navigation.css('max-height', $navigation[0].scrollHeight + "px");
                    $navigation.addClass('expanded');
                }

                $('.categories ul', this.options.node).not($navigation).css('max-height', 0);
                $('.categories p .fa', this.options.node).not($target).removeClass('active');
                $('.categories ul', this.options.node).not($navigation).removeClass('expanded');

            });
        } else {
            // remove accordion

            $('.categories ul', this.options.node).css('max-height', 'none');
            $('.categories p .fa', this.options.node).removeClass('active');

            $('.categories p .fa', this.options.node).off('click');
        }
    }

};


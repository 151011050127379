export function tabNail ($element) {
    if( $($element).length){
        $element.on("click", function (e) {

            $('.appointments-tabs-header .tab-switcher').removeClass('active');
            $('.appointments--tabs .appointments--item').removeClass('in');
            $(this).addClass('active');

            var $tab_name = $(this).data('tab');
            //console.log($tab_name);

            if( $('.appointments--tabs .appointments--item').hasClass($tab_name)){
                $('.appointments--tabs .appointments--item' + '.' + $tab_name).addClass('in');
                console.log('.appointments--tabs .appointments--item' + '.' + $tab_name);
            }
        });
    }
}


export function sbTAbs ($element) {
    if( $($element).length){
        $element.on("click", function (e) {
            e.preventDefault();

            $('.tab-switcher--header .tab-switcher--header-item').removeClass('active');
            $('.wrap-tabs .tab--item').removeClass('in active');
            $(this).addClass('in active');

            var $tab_name = $(this).data('tab');
            //console.log('tab name = ' + $tab_name);

            if( $('.wrap-tabs .tab--item').hasClass($tab_name)){
                $('.wrap-tabs .tab--item' + '.' + $tab_name).addClass('in');
                //console.log('.wrap-tabs .tab--item' + '.' + $tab_name);
            }
        });
    }
}
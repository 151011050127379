export class simpleAccordion {

    constructor (options) {
        this._initSimpleAccordion();
    }

    _initSimpleAccordion(){
        let $accordionHeader = $('.accordion-title');

        if( $($accordionHeader).length ){
            let $accordionContent = $('.accordion-body');

            $accordionHeader.on('click', function (e) {
                let dropDown = $(this).closest('.accordion-card').find('.accordion-body');
                let singleAccordion = $(this).data('single');


                if( singleAccordion ){
                    //console.log('single mode')
                    $(this).toggleClass('active')
                } else {
                    $(this).closest('.accordion-list').find('.accordion-body').not(dropDown).slideUp();

                    if( $(this).hasClass('active') ){
                        $(this).removeClass('active');
                        //console.log('remove class active 1')
                    } else {
                        $(this).closest('.accordion-list').find('.accordion-title.active').removeClass('active');
                        //console.log('remove class active 2');
                        $(this).addClass('active')
                    }
                }

                dropDown.stop(false, true).slideToggle();
                e.preventDefault();

            });

        }
    }

}
const {onModeEnter, onModeLeave, getMode} = require('./media.js');

export class StickyHeader {

    constructor(options) {
        this.options = $.extend({
            node: $('#header'),
            threshold: 66
        }, options);

        document.addEventListener('scroll', () => {
            let $sticky = this.options.node,
                scroll = $(window).scrollTop();

            if (scroll > this.options.threshold) {
                $sticky.addClass('header--stickly');
            } else {
                $sticky.removeClass('header--stickly');
            }
        }, {passive: true});
    }

}


export class StickyTabs {

    constructor(options) {
        this.options = $.extend({}, options);

        this._initStickyTabs();

    }

    _initStickyTabs() {

        let singleTabAnchor = 'is-single-tab';
        if ($('.sticky-anchors').length) {
            if($('.sticky-anchors').hasClass('--no-js')){
                return;
            }

            let navTabs = $('.sticky-anchors'),
                navTabsHeight = navTabs.outerHeight() + 1,
                scrollOffsetVal = 55,
                navTabsOffset = $('.sticky-anchors').offset().top - scrollOffsetVal;

            onModeEnter('desktop', () => {
                navTabs.css('minHeight', navTabsHeight);

            });

            onModeLeave('desktop', () => {
                navTabs.css('minHeight', 50);
                navTabs.removeClass('nav--sticky');
            });

            let lastId,
                navTabsItems = navTabs.find('a'),
                scrollItems = navTabsItems.map(function () {
                    var item = $($(this).attr('href'));
                    if (item.length) {
                        return item;
                    }
                });

            let navSections = $('.anchor-section');

            document.addEventListener('scroll', () => {
                if (getMode() == 'desktop') {
                    let scroll = $(window).scrollTop();

                    if (scroll > navTabsOffset) {
                        navTabs.addClass('nav--sticky');
                        navTabs.css('minHeight', navTabsHeight);
                    } else {
                        navTabs.removeClass('nav--sticky');
                        navTabs.css('minHeight', 50);
                    }
                }

                if (!$('.sticky-anchors').hasClass(singleTabAnchor)) {

                    var fromTop = $(window).scrollTop() + navTabsHeight;
                    var cur = scrollItems.map(function () {
                        if ($(this).offset().top < fromTop)
                            return this;
                    });

                    cur = cur[cur.length - 1];
                    var id = cur && cur.length ? cur[0].id : "";
                    if (lastId !== id) {
                        lastId = id;
                        if (id != '') {
                            navTabsItems.removeClass("active").filter('[href="#' + id + '"]').addClass("active");
                        } else {
                            navTabsItems.removeClass("active")
                        }

                    }
                } else {
                    if ($(window).scrollTop() < navTabs.offset().top + navTabsHeight - 1) {
                        navSections.removeClass('with-pd');
                    }
                }
            }, {passive: true});

            if ($('.sticky-anchors').hasClass(singleTabAnchor)) {
                navTabsItems.on('click', function (event) {
                    let $target = $(event.currentTarget);

                    $target.addClass('active');
                    let $tabButtonAttribute = $target.attr('href').slice(1);

                    $(navSections).each(function () {
                        if ($(this).attr('id').includes($tabButtonAttribute)) {
                            $(this).addClass('active');
                            $(this).addClass('with-pd');
                        } else {
                            $(this).removeClass('active');
                        }
                    });

                    $(navTabsItems).not($target).removeClass('active');
                });
            }

            $(document).ready(function() {
                function checkAnchor(elements, attribute) {
                    elements.each(function() {
                        let $itemAttribute = $(this).attr(attribute);
                        if (window.location.href.indexOf($itemAttribute) > -1) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    });
                    if (elements.filter('.active').length == 0) elements.eq(0).addClass('active');
                }

                checkAnchor(navTabsItems, 'href');
                checkAnchor(navSections, 'id');
            });
        }
    }
}

export class ReviewsSlider {

    constructor(options) {
        this.options = $.extend({
            node: $('.section--reviews-carousel .reviews--slider'),
            slickOptions: {
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true,
                arrows: false
            }
        }, options);

        this.options.node.slick(this.options.slickOptions);
    }

}

export class ReviewsSlider_v2 {

    constructor(options) {
        this.options = $.extend({
            node: $('.reviews--slider-v2'),
            slickOptions: {
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 2,
                adaptiveHeight: false,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            adaptiveHeight: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            }
        }, options);

        this.options.node.slick(this.options.slickOptions);
    }

}


